<template>
  <div>
    <v-container fluid class="form__container">
      <v-row>
        <v-col cols="12">
          <h6 class="text-h6" v-text="$trans('booking_settings')" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex align-center pt-0 mt-0 mb-2">
          <span class="text-body-1 dark--text">{{
            $trans("max_booking_time")
          }}</span>
          <v-select
            v-model="settings.max_booking_time"
            style="max-width: 150px"
            class="ml-3 mr-3"
            dense
            :disabled="disabled"
            :rules="[rules.required]"
            hide-details="auto"
            outlined
            :items="getValues(defaultMinutesValues, settings.max_booking_time)"
          >
            <template slot="item" slot-scope="data">
              <span v-if="data.item > 0">{{ data.item | duration }}</span>
              <span v-else>{{ $trans("no_limit") }}</span>
            </template>
            <template slot="selection" slot-scope="{ item }">
              <span v-if="item > 0">{{ item | duration }}</span>
              <span v-else>{{ $trans("no_limit") }}</span>
            </template>
          </v-select>
          <span class="text-body-1 dark--text hover">
            {{ $trans("before_visit") }}. *
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex align-center pt-0 mt-0 mb-2">
          <span class="text-body-1 dark--text">{{
            $trans("booking_time_before")
          }}</span>
          <v-select
            v-model="settings.booking_time_before"
            style="max-width: 150px"
            class="ml-3 mr-3"
            dense
            :disabled="disabled"
            :rules="[rules.required]"
            hide-details="auto"
            outlined
            :items="
              getValues(defaultMinutesValues, settings.booking_time_before)
            "
          >
            <template slot="item" slot-scope="data">
              {{ data.item | duration }}
            </template>
            <template slot="selection" slot-scope="{ item }">
              {{ item | duration }}
            </template>
          </v-select>
          <span class="text-body-1 dark--text hover">
            {{ $trans("before_visit") }}. *
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex align-center pt-0 mt-0 mb-2">
          <span class="text-body-1 dark--text">{{
            $trans("cancel_time_before")
          }}</span>
          <v-select
            v-model="settings.cancel_time_before"
            :disabled="disabled"
            :rules="[rules.required]"
            dense
            class="ml-3 mr-3"
            style="max-width: 150px"
            hide-details="auto"
            outlined
            :items="
              getValues(defaultMinutesValues, settings.cancel_time_before)
            "
          >
            <template slot="item" slot-scope="{ item }">
              {{ item | duration }}
            </template>
            <template slot="selection" slot-scope="{ item }">
              {{ item | duration }}
            </template>
          </v-select>
          <span class="text-body-1 dark--text hover">
            {{ $trans("before_visit") }}. *
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex align-center pt-0 mt-0 mb-2">
          <span class="text-body-1 dark--text">{{
            $trans("change_time_before")
          }}</span>
          <v-select
            v-model="settings.change_time_before"
            :disabled="disabled"
            :rules="[rules.required]"
            dense
            class="ml-3 mr-3"
            style="max-width: 150px"
            hide-details="auto"
            outlined
            :items="
              getValues(defaultMinutesValues, settings.change_time_before)
            "
          >
            <template slot="item" slot-scope="{ item }">
              {{ item | duration }}
            </template>
            <template slot="selection" slot-scope="{ item }">
              {{ item | duration }}
            </template>
          </v-select>
          <span class="text-body-1 dark--text hover">
            {{ $trans("before_visit") }}. *
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex align-center pt-0 mt-0">
          <span class="text-body-1 dark--text">{{
            $trans("slots_every_checkbox_text")
          }}</span>
          <v-select
            v-model="settings.slots_every"
            :disabled="disabled"
            dense
            style="max-width: 150px"
            class="ml-3 mr-3"
            outlined
            hide-details="auto"
            :items="getValues(slotsEveryMinutesValues, settings.slots_every)"
          >
            <template slot="item" slot-scope="{ item }">
              {{ item | duration }}
            </template>
            <template slot="selection" slot-scope="{ item }">
              {{ item | duration }}
            </template>
          </v-select>
          <span> * </span>
        </v-col>
        <v-col class="d-flex align-center pt-0 mt-0" cols="12">
          <v-checkbox
            v-model="showBreakOptions"
            :disabled="disabled"
            :label="$trans('service_additional_break_info')"
            hide-details
          />
        </v-col>
        <template v-if="showBreakOptions">
          <v-col cols="12" class="d-flex align-center pt-0 mt-0">
            <span class="text-body-1 dark--text">{{
              $trans("break_time_before_checkbox_text")
            }}</span>
            <v-select
              v-model="settings.break_time_before"
              :disabled="disabled"
              dense
              style="max-width: 150px"
              class="ml-3 mr-3"
              outlined
              hide-details="auto"
              :items="
                getValues(breakTimeDefaultValues, settings.break_time_before)
              "
            >
              <template slot="item" slot-scope="{ item }">
                {{ item | duration }}
              </template>
              <template slot="selection" slot-scope="{ item }">
                {{ item | duration }}
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" class="d-flex align-center">
            <span class="text-body-1 dark--text">{{
              $trans("break_time_after_checkbox_text")
            }}</span>
            <v-select
              v-model="settings.break_time_after"
              :disabled="disabled"
              dense
              style="max-width: 150px"
              class="ml-3 mr-3"
              outlined
              hide-details="auto"
              :items="
                getValues(breakTimeDefaultValues, settings.break_time_after)
              "
            >
              <template slot="item" slot-scope="{ item }">
                {{ item | duration }}
              </template>
              <template slot="selection" slot-scope="{ item }">
                {{ item | duration }}
              </template>
            </v-select>
          </v-col>
        </template>
        <v-col cols="12" class="d-flex align-center pt-0 mt-0">
          <v-checkbox
            v-model="enableGroupBookings"
            :disabled="disabled"
            :label="$trans('service_max_people_info')"
            hide-details
          />
        </v-col>
        <v-col v-if="enableGroupBookings" cols="12" class="d-flex align-center">
          {{ $trans("max_people_title") }}
          <v-text-field
            v-model="settings.max_people"
            :rules="[
              rules.required,
              rules.minNumber(settings.max_people, enableGroupBookings ? 2 : 1),
              rules.maxNumber(settings.max_people),
            ]"
            :disabled="disabled"
            dense
            type="number"
            hide-details="auto"
            class="ml-2"
            outlined
            style="max-width: 100px"
          />
        </v-col>
        <v-col cols="12" class="d-flex align-center pt-0 mt-0">
          <v-checkbox
            v-model="limitUserBookings"
            :disabled="disabled"
            :label="$trans('max_user_bookings_checkbox')"
            hide-details
          />
        </v-col>
        <v-col v-if="limitUserBookings" cols="12" class="d-flex align-center">
          {{ $trans("max_user_bookings") }}
          <v-select
            v-model="settings.max_user_bookings"
            style="max-width: 150px"
            class="ml-3 mr-3"
            dense
            :disabled="disabled"
            :rules="[rules.required]"
            hide-details="auto"
            outlined
            :items="getValues(defaultNumbersValues, settings.max_user_bookings)"
          >
            <template slot="item" slot-scope="data">
              <span v-if="data.item > 0">{{ data.item }}</span>
              <span v-else>{{ $trans("no_limit") }}</span>
            </template>
            <template slot="selection" slot-scope="{ item }">
              <span v-if="item > 0">{{ item }}</span>
              <span v-else>{{ $trans("no_limit") }}</span>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-container>
    <v-divider light class="my-6" />
    <v-container fluid class="form__container">
      <v-row>
        <v-col cols="12">
          <h6 class="text-h6" v-text="$trans('service_payments')" />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-if="!settings.only_subscriptions"
          cols="12"
          class="d-flex align-center"
        >
          <v-checkbox
            v-model="settings.allow_online_payment"
            :rules="[rules.isBooleanInteger]"
            :disabled="disabled"
            :false-value="0"
            :true-value="1"
            hide-details="auto"
            class="mt-0 pt-0"
          />
          <span
            class="text-body-1 dark--text hover"
            @click="
              settings.allow_online_payment === 1
                ? (settings.allow_online_payment = 0)
                : (settings.allow_online_payment = 1)
            "
            >{{ $trans("allow_online_payment_2") }}</span
          >
        </v-col>
        <v-col
          v-if="settings.allow_online_payment && !isStripeActive"
          cols="12"
          class="d-flex align-center pt-0 mt-0"
        >
          <calendesk-information-message
            color="orange"
            additional-class="white--text my-4"
            icon-color="white"
            icon="$alert"
          >
            {{ $trans("stripe_payments_warning") }}
            <div class="d-flex align-center justify-center ma-2 mt-4">
              <v-btn color="white" outlined small @click="goToStripe">
                {{ $trans("go_to_stripe") }}
              </v-btn>
            </div>
          </calendesk-information-message>
        </v-col>
        <v-col cols="12" class="d-flex align-center pt-0 mt-0">
          <v-checkbox
            v-model="settings.only_subscriptions"
            :rules="[rules.isBooleanInteger]"
            :disabled="disabled"
            :false-value="0"
            :true-value="1"
            hide-details="auto"
            class="mt-0 pt-0"
          />
          <span
            class="text-body-1 dark--text hover"
            @click="
              settings.only_subscriptions === 1
                ? (settings.only_subscriptions = 0)
                : (settings.only_subscriptions = 1)
            "
            >{{ $trans("only_subscriptions_label") }}</span
          >
        </v-col>
      </v-row>
      <v-row v-if="settings.allow_online_payment">
        <v-col cols="12">
          <calendesk-information-message>
            {{ $trans("service_info_7") }}
          </calendesk-information-message>
        </v-col>
        <v-col cols="12" class="d-flex align-center pt-0 mt-0">
          <v-checkbox
            v-model="settings.payment_required"
            :rules="[rules.isBooleanInteger]"
            :disabled="disabled"
            :false-value="0"
            :true-value="1"
            hide-details="auto"
            class="mt-0 pt-0"
          />
          <span
            class="text-body-1 dark--text hover"
            @click="
              settings.payment_required === 1
                ? (settings.payment_required = 0)
                : (settings.payment_required = 1)
            "
            >{{ $trans("payment_required_2") }}</span
          >
        </v-col>
        <v-col
          v-if="settings.payment_required"
          cols="12"
          class="d-flex align-center pt-0 mt-0 mb-3"
        >
          <span class="text-body-1 dark--text">{{
            $trans("awaiting_booking_payment_time")
          }}</span>
          <v-select
            v-model="settings.awaiting_booking_payment_time"
            style="max-width: 150px"
            class="ml-3 mr-3"
            dense
            :disabled="disabled"
            :rules="[rules.required]"
            hide-details="auto"
            outlined
            :items="
              getValues(
                awaitingBookingTimeDefaultValues,
                settings.awaiting_booking_payment_time,
              )
            "
          >
            <template slot="item" slot-scope="data">
              <span v-if="data.item === 0">{{ $trans("no_limit") }}</span>
              <span v-else>{{ data.item | duration }}</span>
            </template>
            <template slot="selection" slot-scope="{ item }">
              <span v-if="item === 0">{{ $trans("no_limit") }}</span>
              <span v-else>{{ item | duration }}</span>
            </template>
          </v-select>
          <span class="text-body-1 dark--text"
            >{{ $trans("payment_required_3") }} *</span
          >
        </v-col>

        <v-col cols="12" class="d-flex align-center pt-0 mt-0">
          <v-checkbox
            v-model="settings.payment_required_recurring_bookings"
            :rules="[rules.isBooleanInteger]"
            :disabled="disabled"
            :false-value="0"
            :true-value="1"
            hide-details="auto"
            class="mt-0 pt-0"
          />
          <span
            class="text-body-1 dark--text hover"
            @click="
              settings.payment_required_recurring_bookings === 1
                ? (settings.payment_required_recurring_bookings = 0)
                : (settings.payment_required_recurring_bookings = 1)
            "
            >{{ $trans("payment_required_4") }}</span
          >
        </v-col>
        <v-col
          v-if="settings.payment_required_recurring_bookings"
          cols="12"
          class="d-flex align-center pt-0 mt-0"
        >
          <span class="text-body-1 dark--text">{{
            $trans("payment_deadline_before_start")
          }}</span>
          <v-select
            v-model="settings.payment_deadline_before_start"
            style="max-width: 150px"
            class="ml-3 mr-3"
            dense
            :disabled="disabled"
            :rules="[rules.required]"
            hide-details="auto"
            outlined
            :items="
              getValues(
                awaitingBookingTimeDefaultValues,
                settings.payment_deadline_before_start,
              )
            "
          >
            <template slot="item" slot-scope="data">
              <span v-if="data.item === 0">{{ $trans("no_limit") }}</span>
              <span v-else>{{ data.item | duration }}</span>
            </template>
            <template slot="selection" slot-scope="{ item }">
              <span v-if="item === 0">{{ $trans("no_limit") }}</span>
              <span v-else>{{ item | duration }}</span>
            </template>
          </v-select>
          <span class="text-body-1 dark--text"
            >{{ $trans("payment_required_5") }} *</span
          >
        </v-col>
        <v-col
          v-if="settings.payment_required_recurring_bookings"
          cols="12"
          class="d-flex align-center pt-0 mt-0 mb-3"
        >
          <span class="text-body-1 dark--text">{{
            $trans("payment_required_6")
          }}</span>
          <v-select
            v-model="settings.send_payment_reminder_before_start"
            style="max-width: 150px"
            class="ml-3 mr-3"
            dense
            :disabled="disabled"
            :rules="[rules.required]"
            hide-details="auto"
            outlined
            :items="
              getValues(
                awaitingBookingTimeDefaultValues,
                settings.send_payment_reminder_before_start,
              )
            "
          >
            <template slot="item" slot-scope="data">
              <span v-if="data.item === 0">{{ $trans("no_limit") }}</span>
              <span v-else>{{ data.item | duration }}</span>
            </template>
            <template slot="selection" slot-scope="{ item }">
              <span v-if="item === 0">{{ $trans("no_limit") }}</span>
              <span v-else>{{ item | duration }}</span>
            </template>
          </v-select>
          <span class="text-body-1 dark--text"
            >{{ $trans("payment_required_7") }} *</span
          >
        </v-col>
      </v-row>
      <v-row v-if="settings.allow_online_payment">
        <v-col cols="12">
          <calendesk-information-message>
            {{ $trans("invoice_info_1") }}
          </calendesk-information-message>
        </v-col>
        <v-col cols="12" class="d-flex align-center pt-0 mt-0">
          <v-checkbox
            v-model="settings.wants_invoice"
            :rules="[rules.isBooleanInteger]"
            :disabled="disabled"
            :false-value="0"
            :true-value="1"
            hide-details="auto"
            class="mt-0 pt-0"
          />
          <span
            class="text-body-1 dark--text hover"
            @click="
              settings.wants_invoice === 1
                ? (settings.wants_invoice = 0)
                : (settings.wants_invoice = 1)
            "
            >{{ $trans("wants_invoice_select_title") }}</span
          >
        </v-col>
      </v-row>
      <v-row v-if="settings.wants_invoice && settings.allow_online_payment">
        <v-col cols="12" class="d-flex align-center">
          {{ $trans("tax_amount_title") }}
          <v-select
            v-model="settings.tax"
            :disabled="disabled"
            dense
            style="max-width: 150px"
            class="ml-2"
            outlined
            hide-details="auto"
            :title="$trans('select')"
            append-icon="$percent"
            :items="taxValues"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  isBooleanInteger,
  maxChars,
  maxNumber,
  minNumber,
  required,
} from "@/lib/calendesk-js-library/forms/validators";
import {
  durationList,
  getTaxValues,
} from "@/lib/calendesk-js-library/tools/helpers";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage.vue";
import planActions from "@/calendesk/mixins/planActions";

export default {
  name: "ServiceBookingSettings",
  components: { CalendeskInformationMessage },
  mixins: [planActions],
  props: {
    currentSettings: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      taxValues: getTaxValues(),
      showBreakOptions: false,
      enableGroupBookings: false,
      limitUserBookings: false,
      rules: {
        required,
        isBooleanInteger,
        maxChars,
        minNumber,
        maxNumber,
      },
      defaultNumbersValues: [...Array(101).keys()],
      settings: {
        booking_time_before: 1440,
        cancel_time_before: 1440,
        change_time_before: 1440,
        max_booking_time: 259200,
        max_user_bookings: 0,
        slots_every: 30,
        break_time_before: 0,
        break_time_after: 0,
        allow_online_payment: 1,
        only_subscriptions: 0,

        payment_required: 0, // single booking
        awaiting_booking_payment_time: 60, //single bookings

        payment_required_recurring_bookings: 0, // recurring bookings
        payment_deadline_before_start: 60, // recurring bookings
        send_payment_reminder_before_start: 1440,

        max_people: 1,
        wants_invoice: 0,
        tax: "23",
      },
    };
  },
  computed: {
    breakTimeDefaultValues() {
      const generate0 = durationList(0, 60, 1, true);
      const generate1 = durationList(60, 720, 5, true);

      return generate0.concat(generate1);
    },
    awaitingBookingTimeDefaultValues() {
      const generate0 = [0];
      const generate1 = durationList(30, 360, 5, true);
      const generate2 = durationList(360, 1440, 15, true);
      const generate3 = durationList(1440, 20160, 1440, true);

      return generate0.concat(generate1, generate2, generate3);
    },
    defaultMinutesValues() {
      const generate0 = durationList(0, 60, 1, true);
      const generate1 = durationList(60, 1440, 30, true);
      const generate2 = durationList(1440, 525600, 1440, true);

      return generate0.concat(generate1, generate2);
    },
    slotsEveryMinutesValues() {
      const generate0 = durationList(1, 60, 1, true);
      const generate1 = durationList(60, 720, 5, true);

      return generate0.concat(generate1);
    },
  },
  watch: {
    enableGroupBookings(val) {
      if (!val) {
        this.settings.max_people = 1;
      }
    },
    showBreakOptions(val) {
      if (!val) {
        this.settings.break_time_before = 0;
        this.settings.break_time_after = 0;
      }
    },
    limitUserBookings(val) {
      if (!val) {
        this.settings.max_user_bookings = 0;
      }
    },
    settings: {
      deep: true,
      handler() {
        if (this.settings.only_subscriptions) {
          this.settings.allow_online_payment = 0;
          this.settings.payment_required = 0;
          this.settings.payment_required_recurring_bookings = 0;
          this.settings.wants_invoice = 0;
          this.settings.tax = null;
        }

        const settingsToSend = { ...this.settings };

        this.$emit("booking-data", settingsToSend);
      },
    },
    currentSettings: {
      deep: true,
      handler(obj) {
        if (obj) {
          this.settings.allow_online_payment = obj.allow_online_payment;
          this.settings.only_subscriptions = obj.only_subscriptions;
          this.settings.wants_invoice = obj.wants_invoice;
          this.settings.tax = obj.tax;

          this.settings.payment_required = obj.payment_required;
          this.settings.awaiting_booking_payment_time =
            obj.awaiting_booking_payment_time;

          this.settings.payment_required_recurring_bookings =
            obj.payment_required_recurring_bookings;
          this.settings.payment_deadline_before_start =
            obj.payment_deadline_before_start;
          this.settings.send_payment_reminder_before_start =
            obj.send_payment_reminder_before_start;

          this.settings.booking_time_before = obj.booking_time_before;
          this.settings.max_booking_time = obj.max_booking_time;
          this.settings.max_user_bookings = obj.max_user_bookings;
          this.settings.cancel_time_before = obj.cancel_time_before;
          this.settings.change_time_before = obj.change_time_before;
          this.settings.break_time_before = obj.break_time_before;
          this.settings.break_time_after = obj.break_time_after;
          this.settings.slots_every = obj.slots_every;
          this.settings.max_people = obj.max_people;

          this.showBreakOptions =
            this.settings.break_time_before > 0 ||
            this.settings.break_time_after > 0;

          if (!this.enableGroupBookings) {
            this.enableGroupBookings = this.settings.max_people > 1;
          }

          if (!this.limitUserBookings) {
            this.limitUserBookings = this.settings.max_user_bookings > 0;
          }
        }
      },
    },
  },
  methods: {
    getValues(array, value) {
      return array.includes(value) ? array : [value, ...array];
    },
    goToStripe() {
      this.$router.push({
        name: "integrations",
        params: { integration: "stripe" },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.hover {
  cursor: pointer;
}

.message-slot {
  position: absolute;
  width: 200px;
}
</style>
